import { graphql } from "gatsby"
import React from "react"
import Banner from "../components/fintechRevamp/Banner"
import ContactSales from "../components/common/ContactSales"
import Faqs from "../components/common/Faqs2"
import MainLayout from "../layouts/MainLayout"
import Services from "../components/fintechRevamp/Services"
import WhyChoos from "../components/fintechRevamp/WhyChoos"
import Hire from "../components/fintechRevamp/Hire"
import Accelerate from "../components/fintechRevamp/Accelerate"
import TechStacks from "../components/fintechRevamp/TechStacks"
import NextGen from "../components/fintechRevamp/NextGen"
import Outsource from "../components/e-Commerce/Outsource"
import EngagementModels from "../components/common/Engagement-Model/EngagementModels"
import WorkPortfolio from "../components/common/WorkPortfolio"
import Testimonials from "../components/fintechRevamp/Testimonials"
import Testimonials2 from "../components/fintechRevamp/Testimonials2"
import FintechProcess from "../components/fintechRevamp/FintechProcess"
import MobServices from "../components/fintechRevamp/MobServices"
import SEORevamp from "../components/common/SEO_Revamp"

const fintech = ({ data }) => {
  // console.log(data)
  const [isMobile, setIsMobile] = React.useState(false)
  const [isTablet, setIsTablet] = React.useState(false)

  const handleResize = () => {
    if (window.innerWidth <= 767) {
      setIsMobile(true)
      setIsTablet(false)
    } else if (window.innerWidth <= 1280) {
      setIsTablet(true)
      setIsMobile(false)
    } else {
      setIsMobile(false)
      setIsTablet(false)
    }
  }

  React.useEffect(() => {
    setIsMobile(window.innerWidth <= 767)
    setIsTablet(window.innerWidth <= 1280)
    window.addEventListener("resize", handleResize)
    return () => {
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  const heroSection = data?.strapiPage?.sections[0]
  const services = data?.strapiPage?.sections[1]
  const gen = data?.strapiPage?.sections[2]
  const accelerate = data?.strapiPage?.sections[3]
  const process = data?.strapiPage?.sections[4]
  const techStacks = data?.strapiPage?.sections[5]
  const outSource = data?.strapiPage?.sections[6]
  const choos = data?.strapiPage?.sections[7]
  const models = data?.strapiPage?.sections[8]
  const hire = data?.strapiPage?.sections[9]
  const portfolio = data?.strapiPage?.sections[10]
  const testimonials = data?.testimonials?.sections[0]
  const faqs = data?.strapiPage?.sections[12]
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <MainLayout schemas={bodySchema}>
      <Banner strapiData={heroSection} breadCrumb={data?.strapiPage?.buttons} />
      {!isMobile && !isTablet ? (
        <Services strapiData={services} />
      ) : (
        <MobServices strapiData={services} />
      )}
      <NextGen strapiData={gen} />
      <Accelerate strapiData={accelerate} />
      <FintechProcess strapiData={process} />
      <TechStacks strapiData={techStacks} />
      <Outsource
        strapiData={outSource}
        btnName={"Book A Free Call"}
        pageName="Fintech Service"
      />
      <WhyChoos strapiData={choos} />

      <EngagementModels strapiData={models} />

      <Hire strapiData={hire} />
      <WorkPortfolio strapiData={portfolio} />
      {!isMobile && !isTablet ? (
        <Testimonials strapiData={testimonials} />
      ) : (
        <Testimonials2 strapiData={testimonials} />
      )}

      <Faqs strapiData={faqs} />
      <ContactSales bgColor="#f8f8f8" />
    </MainLayout>
  )
}

export const query = graphql`
  query ElixirPage {
    strapiPage(slug: { eq: "fintech-revamp" }) {
      sections {
        title
        subTitle
        subTitle2
        cards {
          title
          subTitle
          description: childStrapiComponentCardsCardDescriptionTextnode {
            description
          }
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
          image2 {
            alternativeText
            localFile {
              publicURL
            }
          }
          buttons {
            title
            url
          }
        }
        secImages {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            publicURL
          }
          url
        }
        buttons {
          title
          url
        }
        description: childStrapiComponentSectionsSectionDescriptionTextnode {
          id
          description
        }
      }
      buttons {
        url
        title
      }
      seo {
        metaTitle
        metaDescription
        schemas {
          visibilityIn
          structuredData
        }
      }
    }
    testimonials: strapiPage(slug: { eq: "testimonial" }) {
      sections {
        title
        subTitle
        cards {
          title
          subTitle
          image1 {
            alternativeText
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default fintech

export const Head = ({ data }) => {
  const { metaTitle, metaDescription } = data?.strapiPage?.seo
  let headSchema = []
  const bodySchema = data?.strapiPage?.seo?.schemas?.filter(v => {
    return v?.visibilityIn === true ? v : headSchema?.push(v) && false
  })

  return (
    <SEORevamp
      title={metaTitle}
      description={metaDescription}
      schemas={headSchema}
      image="https://invozone-backend.s3.amazonaws.com/leading_react_native_app_development_company_e5e33e137c.webp"
    />
  )
}
