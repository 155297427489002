import { Link } from "gatsby"
import React, { useEffect } from "react"
import * as styles from "./FintechProcess.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import processLine from "../../images/fintechRevamp/process-lines.svg"
import AOS from "aos"
import "aos/dist/aos.css"
const FintechProcess = ({ strapiData }) => {
  useEffect(() => {
    AOS.init({
      disable: function () {
        var maxWidth = 1024
        return window.innerWidth < maxWidth
      },
    })
  }, [])

  return (
    <div className={styles.mainProcess}>
      <Container>
        <h2
          className={styles.heading}
          dangerouslySetInnerHTML={{ __html: strapiData?.title }}
        />
        <div
          className={styles.description}
          dangerouslySetInnerHTML={{
            __html: strapiData?.subTitle,
          }}
        />
        <Row className={`gap-30 ${styles.cardWrapper}`}>
          {strapiData?.cards?.map((e, i) => (
            <Col
              xl={3}
              md={6}
              xs={12}
              sm={12}
              data-aos="fade-up"
              data-aos-delay={
                i === 1
                  ? "700"
                  : i === 2
                  ? "500"
                  : i === 3
                  ? "400"
                  : i === 4
                  ? "200"
                  : "200"
              }
              data-aos-duration={
                i === 1
                  ? "2000"
                  : i === 2
                  ? "3000"
                  : i === 3
                  ? "2000"
                  : i === 4
                  ? "2000"
                  : "5000"
              }
              data-aos-once={true}
              key={i}
              className={`gap-30 d-flex`}
            >
              <div
                className={
                  i === 0
                    ? styles.card
                    : i === 1
                    ? styles.card2
                    : i === 2
                    ? styles.card3
                    : i === 3
                    ? styles.card4
                    : ""
                }
              >
                <div className={styles.cardImg}>
                  <img
                    src={e?.image1[0]?.localFile?.publicURL}
                    decoding="async"
                    loading="lazy"
                    alt={e?.title}
                  />
                </div>
                <h3>{e?.title}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: e?.subTitle,
                  }}
                />
                <span>{i + 1}</span>
              </div>
            </Col>
          ))}
        </Row>
        <Row className={`${styles.lineRow}`}>
          <Col className="text-center">
            <img src={processLine} alt="" decoding="async" loading="lazy" />
            <div className={styles.fintechTeamBtn}>
              {strapiData?.buttons[0] && (
                <Link
                  to={`/${strapiData?.buttons[0]?.url}/`}
                  className={styles.finTechbtn}
                >
                  {strapiData?.buttons[0]?.title}
                </Link>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default FintechProcess
