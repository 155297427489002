// extracted by mini-css-extract-plugin
export var aurther = "Testimonials2-module--aurther--eb94d";
export var circleTes = "Testimonials2-module--circleTes--66cb1";
export var content = "Testimonials2-module--content--ca2a4";
export var des = "Testimonials2-module--des--43420";
export var iconContainer = "Testimonials2-module--iconContainer--427f8";
export var iconContainerLeft = "Testimonials2-module--iconContainerLeft--cb51c";
export var personBg = "Testimonials2-module--personBg--c8ee7";
export var portfolioArrowIcon = "Testimonials2-module--portfolioArrowIcon--6e1a2";
export var portfolioArrowIconCover = "Testimonials2-module--portfolioArrowIconCover--99f35";
export var portfolioArrowRightIconCover = "Testimonials2-module--portfolioArrowRightIconCover--4eba1";
export var row = "Testimonials2-module--row--02649";
export var section = "Testimonials2-module--section--837d7";
export var tesBtn = "Testimonials2-module--tesBtn--a703c";
export var tesDots = "Testimonials2-module--tesDots--824c6";
export var tesRec = "Testimonials2-module--tesRec--305ad";
export var testimonials = "Testimonials2-module--testimonials--50b38";