// extracted by mini-css-extract-plugin
export var clientDots = "Testimonials-module--clientDots--d326a";
export var clientDots2 = "Testimonials-module--clientDots2--22f98";
export var content = "Testimonials-module--content--76499";
export var dots3 = "Testimonials-module--dots3--7cec1";
export var hireArrow = "Testimonials-module--hireArrow--7c7ad";
export var iconContainer = "Testimonials-module--iconContainer--ac437";
export var iconContainerLeft = "Testimonials-module--iconContainerLeft--b403a";
export var imgBtn = "Testimonials-module--imgBtn--92e04";
export var person = "Testimonials-module--person--4b2b5";
export var personBg = "Testimonials-module--personBg--27523";
export var portfolioArrowIcon = "Testimonials-module--portfolioArrowIcon--43b29";
export var portfolioArrowIconCover = "Testimonials-module--portfolioArrowIconCover--be582";
export var portfolioArrowRightIconCover = "Testimonials-module--portfolioArrowRightIconCover--ec595";
export var row = "Testimonials-module--row--320e6";
export var section = "Testimonials-module--section--2594d";
export var staffRec = "Testimonials-module--staffRec--05211";
export var testName = "Testimonials-module--testName--ca56a";
export var testimonials = "Testimonials-module--testimonials--9ddd7";
export var testimonialsContent = "Testimonials-module--testimonialsContent--1bb3c";
export var vector2 = "Testimonials-module--vector2--7eaea";