// extracted by mini-css-extract-plugin
export var btn = "WhyChoos-module--btn--cab74";
export var cards = "WhyChoos-module--cards--ce66e";
export var description = "WhyChoos-module--description--3281b";
export var hire = "WhyChoos-module--hire--fcb36";
export var hireCyberWhyChoose = "WhyChoos-module--hireCyberWhyChoose--ebff6";
export var hireImg = "WhyChoos-module--hireImg--e7fc5";
export var hireImgDiv = "WhyChoos-module--hireImgDiv--3d543";
export var hireImgRight = "WhyChoos-module--hireImgRight--cf186";
export var talent = "WhyChoos-module--talent--b4217";
export var teamBtn = "WhyChoos-module--teamBtn--8434e";
export var text = "WhyChoos-module--text--26273";
export var tick = "WhyChoos-module--tick--265d9";