// extracted by mini-css-extract-plugin
export var card = "FintechProcess-module--card--c3d5a";
export var card2 = "FintechProcess-module--card2--880a1";
export var card3 = "FintechProcess-module--card3--be23d";
export var card4 = "FintechProcess-module--card4--1b6af";
export var cardImg = "FintechProcess-module--cardImg--ce7b0";
export var cardWrapper = "FintechProcess-module--cardWrapper--60ca7";
export var description = "FintechProcess-module--description--4c669";
export var finTechbtn = "FintechProcess-module--finTechbtn--77887";
export var fintechTeamBtn = "FintechProcess-module--fintechTeamBtn--d95a3";
export var heading = "FintechProcess-module--heading--8649f";
export var lineRow = "FintechProcess-module--lineRow--7893a";
export var mainProcess = "FintechProcess-module--mainProcess--ddb02";