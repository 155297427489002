// extracted by mini-css-extract-plugin
export var accImg = "NextGen-module--accImg--ff93b";
export var approachImg = "NextGen-module--approachImg--07471";
export var btn_white1_border = "NextGen-module--btn_white1_border--da53a";
export var description = "NextGen-module--description--d0d44";
export var head = "NextGen-module--head--b5f60";
export var heading = "NextGen-module--heading--3bfef";
export var keyBtn = "NextGen-module--keyBtn--fa23e";
export var nextGenBehold = "NextGen-module--nextGenBehold--cffcb";
export var nextGenRight = "NextGen-module--nextGenRight--f2067";
export var quesColor = "NextGen-module--quesColor--8664b";
export var quesColorSelected = "NextGen-module--quesColorSelected--c14b7";
export var someColor = "NextGen-module--someColor--87175";
export var textColor = "NextGen-module--textColor--4ea50";