// extracted by mini-css-extract-plugin
export var cardBg = "TechStacks-module--cardBg--a83c3";
export var cards = "TechStacks-module--cards--498b1";
export var description = "TechStacks-module--description--cb00a";
export var heading = "TechStacks-module--heading--dd3c6";
export var infaHeading = "TechStacks-module--infaHeading--d0b45";
export var nav = "TechStacks-module--nav--8641b";
export var navItem = "TechStacks-module--nav-item--3c485";
export var navbarBlock = "TechStacks-module--navbarBlock--0fc5f";
export var tabData = "TechStacks-module--tabData--12b37";
export var tech = "TechStacks-module--tech--a4b21";
export var techIconssCard = "TechStacks-module--techIconssCard--d549b";
export var techInfrastructures = "TechStacks-module--techInfrastructures--8dc7b";
export var techStackImg = "TechStacks-module--techStackImg--8d3a5";
export var techStackglobalIndus = "TechStacks-module--techStackglobalIndus--099b0";
export var techStacksCardsContent = "TechStacks-module--techStacksCardsContent--7c874";
export var techStacksIcon = "TechStacks-module--techStacksIcon--fd489";
export var technologyStacksIcon = "TechStacks-module--technologyStacksIcon--5a0d6";