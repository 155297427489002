// extracted by mini-css-extract-plugin
export var btn = "Hire-module--btn--7e856";
export var cards = "Hire-module--cards--ca5f7";
export var description = "Hire-module--description--830c4";
export var hire = "Hire-module--hire--1cde4";
export var hireCyberFinTech = "Hire-module--hireCyberFinTech--4712a";
export var hireImg = "Hire-module--hireImg--b5703";
export var talent = "Hire-module--talent--04843";
export var teamBtn = "Hire-module--teamBtn--d1cba";
export var text = "Hire-module--text--5635d";
export var tick = "Hire-module--tick--953ec";