// extracted by mini-css-extract-plugin
export var SliderWidth = "Services-module--SliderWidth--00c68";
export var arrowImg = "Services-module--arrowImg--e8126";
export var card = "Services-module--card--25a09";
export var cardWrapper = "Services-module--cardWrapper--1fc0a";
export var cards = "Services-module--cards--91f78";
export var description = "Services-module--description--7f245";
export var heading = "Services-module--heading--42d71";
export var headingPremium = "Services-module--headingPremium--f6d41";
export var iconContainer = "Services-module--iconContainer--f19ef";
export var iconContainerLeft = "Services-module--iconContainerLeft--7f05e";
export var mainWork = "Services-module--mainWork--bba51";
export var portfolioArrowIcon = "Services-module--portfolioArrowIcon--2d1ec";
export var portfolioArrowIconCover = "Services-module--portfolioArrowIconCover--f633e";
export var portfolioArrowRightIconCover = "Services-module--portfolioArrowRightIconCover--fc153";
export var premiumImg = "Services-module--premiumImg--1b362";
export var providingImg = "Services-module--providingImg--3fe96";
export var teamButton = "Services-module--teamButton--8bbab";
export var trailBg = "Services-module--trailBg--fd081";